<template>
  <div class="text999 langs">
    <el-dropdown @command="handleCommand"
                 trigger="click">
      <span class="el-dropdown-link">
        {{ value }}<i class="el-icon-caret-bottom"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-for="item in options"
                          :key="item.lang"
                          :command="item">{{ item.lang }}</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [
        {
          value: "zh",
          lang: "CN",
        },
        {
          value: "en",
          lang: "EN",
        },
        {
          value: "ja",
          lang: "JP",
        },
      ],
      value: "CN",
    };
  },
  computed: {
    language () {
      return this.$store.getters.language;
    },
  },
  methods: {
    handleSetLanguage (lang) {
      this.$i18n.locale = lang;
      this.$store.dispatch("app/setLanguage", lang);
      this.$message({
        message: "Switch Language Success",
        type: "success",
      });
    },
    // 监听选择
    handleCommand (command) {
      // console.log(command);
      this.value = command.lang;
      this.$i18n.locale = command.value;
      this.$store.dispatch("app/setLanguage", command.value);
    },
  },
  created () {
    if (this.$store.getters.language == "zh") {
      this.value = "CN";
    }
    if (this.$store.getters.language == "en") {
      this.value = "EN";
    }
    if (this.$store.getters.language == "ja") {
      this.value = "JP";
    }
  },
};
</script>
<style scoped>
.langs {
  /* width: 40px;
  height: 15px; */
  border: 1px solid #ededed;
  border-radius: 2px;
  padding: 0 6px 0 8px;
}
</style>
