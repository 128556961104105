<template>
  <!-- 头部 -->
  <div class="header">
    <div class="flex align-center justify-between hp100 plr13 bbox">
      <div class="flex align-center">
        <img src="../assets/images/logo.png"
             alt=""
             width="66"
             height="19"
             @click="jump('/index')" />
        <lang-select class="ml15"></lang-select>
      </div>
      <div class="flex align-center">
        <!-- 搜索 -->
        <img src="../assets/images/search.png"
             alt=""
             width="18"
             height="19"
             @click="jump('/search')" />
        <!-- 头像 -->
        <img :src="user.logo"
             :onerror="errorImg"
             alt=""
             width="20"
             height="20"
             class="ml20 radius50"
             @click="jump('/my')" />
        <!-- 更多菜单 -->
        <img src="../assets/images/more.png"
             alt=""
             width="20"
             height="16"
             class="ml20"
             @click="showNav()" />
      </div>
      <!-- 弹框 -->
      <ul class="navPop"
          style="z-index: 100"
          v-show="isShowNav">
        <li @click="jump('/index')">{{$t('home')}}</li>
        <li @click="jump('/science')">{{$t('iconMenu.academic')}}</li>
        <li @click="jump('/instrumentData', '故障手册')">{{$t('iconMenu.fault')}}</li>
        <li @click="jump('/instrumentData', '新信息发布')">{{$t('iconMenu.maintain')}}</li>
        <li @click="jump('/examType')">{{$t('iconMenu.exam')}}</li>
        <li @click="jump('/tools')">{{$t('iconMenu.tool')}}</li>
        <li @click="jump('/instrumentData', '资料下载')">{{$t('iconMenu.upload')}}</li>
      </ul>
      <!-- 弹框 -->
    </div>
  </div>
</template>

<script>
import LangSelect from "../components/LangSelect.vue";
export default {
  components: { LangSelect },
  data () {
    return {
      isShowNav: 0,
      imgUrl: "",
      errorImg: 'this.src="' + require("../assets/images/avatar.png") + '"',
      user: {
        logo:
          "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
        name: "请先登录",
        type: "",
      },
    };
  },
  methods: {
    //显示隐藏导航栏
    showNav () {
      this.isShowNav = !this.isShowNav;
    },
    // 跳转页面
    jump (path, key) {
      var pushData = {};
      pushData.path = path;
      pushData.query = {};
      pushData.query.from = key;
      pushData.query.key = key;
      this.$router.push(pushData);
    },
    //改变元素#searchBar的top值
    handleScroll () {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // console.log("滚动条距离顶部" + scrollTop);
      if (scrollTop > 0) {
        $(".header").addClass("handleScorll");
      } else {
        $(".header").removeClass("handleScorll");
      }
    },
    handleSearch () {
      this.$router.push({ path: "/search" });
    },
  },
  created () {
    /**
     * 加载会员数据
     */
    var member = JSON.parse(localStorage.getItem("member"));
    var memberCode = "";
    if (null != member) {
      if (typeof undefined === member.isLogin || null == member.isLogin)
        member.isLogin = false;
      if (member.isLogin) {
        this.user.name = member.name;
        this.isLogin = true;
        if (
          !(
            undefined == member.logo ||
            null == member.logo ||
            "" == member.logo
          )
        )
          this.user.logo = member.logo;
      }
      memberCode = member.memberCode;
    }
  },
  mounted () {
    //给window添加一个滚动滚动监听事件
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.header {
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 100;
  border-bottom: 1px solid #ededed;
}
/* 导航栏 */
.navPop {
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  width: 90px;
  text-align: center;
  position: fixed;
  top: 50px;
  right: 0;
  padding: 4px 0;
}
.navPop li {
  padding: 6px 12px;
  font-size: 12px;
}
.handleScorll {
  box-shadow: 5px 0 15px rgba(0, 0, 0, 0.1);
}
</style>