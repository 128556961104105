<template>
  <div>
    <!-- 头部 -->
    <Header></Header>
    <div class="BoxHeight bbox">
      <!-- 右侧 -->
      <div class="bbox pt20 plr15 pb20 positionr flex1">
        <div v-if="fileList.length == 0"
             class="noMessage positiona">
          <img src="../../assets/images/kong.png"
               alt=""
               width="100" />
          <div class="f14 textc mt15">{{$t('noData')}}</div>
        </div>
        <div class="list text666 f13"
             v-else>
          <div class="item flex align-start"
               v-for="file in fileList"
               :key="file.objectCode">
            <span class="flex1">{{ file.typeName }}</span>
            <div class="upload ml10">
              <img src="../../assets/images/download.png"
                   alt=""
                   width="18"
                   height="18"
                   class="vm" />
              <span class="text-blue pl5 vm"
                    @click="downloadFile(file.typeCode, file.typeName)">{{$t('my.download')}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 页脚 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../components/commonHead.vue";
import Footer from "../../components/footer.vue";
export default {
  components: { Header, Footer },
  data () {
    return {
      list: [1],
      fileList: [],
    };
  },
  methods: {
    loadFileList () {
      var sendData = {};
      sendData.typeCode = this.typeCode;
      sendData.typeName = this.typeName;
      var that = this;
      this.$http
        .post("machineType/queryMachineDownLoadFileList", sendData)
        .then(function (res) {
          if (200 == res.data.code) {
            that.fileList = res.data.data;
          }
        });
    },
    downloadFile (typeCode, typeName) {
      var sendData = {};
      sendData.objectCode = typeCode;
      var that = this;
      window.location="https://www.arkray-service.com.cn/api/annexFile/downFileList?objectCode="+typeCode
      // this.$http.post("annexFile/queryFileList", sendData).then(function (res) {
      //   if (200 == res.data.code) {
      //     var file = res.data.data[0];
      //     if (file != undefined && file.fileUrl != undefined) {
      //       console.log("接口返回信息：",file);
      //       var url = file.fileUrl;
      //       console.log("文件下载地址：",url);
      //       var fileName = url.split("_")[1];
      //       var fileNameArray = fileName.split(".");
      //       var url = window.URL.createObjectURL(new Blob([url]));
      //       if(fileName.indexOf("pdf")!=-1){
      //         url = window.URL.createObjectURL(new Blob([url],{
      //           type: `application/pdf;charset-UTF-8`//word文档为msword,pdf文档为pdf
      //         }));
      //       }
      //       var link = document.createElement("a");
      //       link.style.display = "none";
      //       link.href = url;
      //       link.setAttribute("download", fileName); // 文件名
      //       document.body.appendChild(link);
      //       link.click();
      //     }
      //   }
      // });
    },
  },
  created () {
    this.typeCode = this.$route.query.typeCode;
    this.typeName = this.$route.query.typeName;
    this.loadFileList();
  },
};
</script>

<style scoped>
.list > div {
  padding: 8px 0;
  border-bottom: 1px solid #ededed;
}
.upload {
  width: 52px;
}
</style>